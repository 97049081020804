<template>
  <label class="radio" :for="id" :class="{ checked: value == fieldValue }">
    <input
      :id="id"
      :checked="value === fieldValue"
      :value="fieldValue"
      :name="name"
      type="radio"
      :disabled="disabled"
      @input="$emit('input', fieldValue)"
    />
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: String,
    id: String,
    name: String,
    value: String,
    fieldValue: String,
    disabled: Boolean,
    handleRadio: Function,
  },
};
</script>
