<template>
  <div>
    <Emergency />
  </div>
</template>

<script>
import Emergency from '@/components/Emergency/Emergency.vue';

export default {
  name: 'EmergencyPage',
  components: {
    Emergency,
  },
};
</script>
