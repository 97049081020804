<template>
  <div>
    <section class="container-sm px-1 md:pt-2-half">
      <div class="emergency-box mb-1">
        <h1>{{ $t('EmergencyHeader') }}</h1>
        <p>{{ emergencySubTextHeader }}</p>
        <ul>
          <li v-for="item in emergencySubTextList" :key="item">{{ item }}</li>
        </ul>
      </div>

      <form>
        <RadioButton
          id="experiencingSymptoms"
          v-model="emergency"
          :label="$t('EmergencySymptomsOption')"
          :fieldValue="'isEmergency'"
          name="emergencySymptoms"
          data-test="experiencing-symptoms"
        />
        <RadioButton
          id="nonEmergency"
          v-model="emergency"
          :label="$t('NoEmergencySymptomsOption')"
          :fieldValue="'nonEmergency'"
          name="emergencySymptoms"
          data-test="no-symptoms"
        />

        <div class="text-center">
          <button
            id="emergencyContinue"
            disabled
            class="button extra-large mt-2-half"
            data-test="emergency-page-continue-button"
            @click.prevent="checkIfEmergency()"
          >
            {{ $t('Continue') }}
          </button>
        </div>
      </form>
    </section>
  </div>
</template>

<script>
import Vue from 'vue';
import RadioButton from '@/components/RadioButton';

export default Vue.extend({
  components: {
    RadioButton,
  },
  data() {
    return {
      emergency: '',
    };
  },
  computed: {
    emergencySubTextHeader() {
      return this.$t('EmergencySubtext').split('\n')[0];
    },
    emergencySubTextList() {
      return this.$t('EmergencySubtext').split('\n').slice(1);
    },
  },
  mounted() {
    const choices = document.querySelectorAll('[name=emergencySymptoms]');
    const emergencyCta = document.getElementById('emergencyContinue');
    for (let i = 0, max = choices.length; i < max; i++) {
      choices[i].onclick = function () {
        emergencyCta.disabled = false;
      };
    }
  },
  methods: {
    checkIfEmergency() {
      const route =
        this.emergency === 'isEmergency' ? 'emergency-warning' : 'triage';
      this.$router.push(route);
    },
  },
});
</script>
